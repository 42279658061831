<template>
  <v-container fluid>
    <v-overlay v-if="progress">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-form
      v-if="topping != null && menus.length != 0"
      ref="form"
      v-model="valid"
      class="ma-4"
    >
      <!-- 名前 -->
      <v-row>
        <v-col sm="6" class="pt-0 pb-0">
          <v-text-field
            v-model="topping.name"
            placeholder="例) ごはんの量"
            persistent-placeholder
            outlined
            :rules="rules.required"
          >
            <template v-slot:label>
              名前<span class="ml-2 red--text">必須</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <!-- メモ -->
      <v-row>
        <v-col cols="12" sm="6" class="pt-0 pb-0">
          <v-text-field
            v-model="topping.memo"
            label="説明"
            placeholder="例) 定食用"
            persistent-placeholder
            outlined
            :rules="[rules.length15]"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <!-- ヘルプ -->
      <v-row>
        <v-col cols="12" sm="6" class="pt-0 pb-0">
          <v-text-field
            v-model="topping.help.question"
            label="ヘルプ (質問)"
            placeholder="例) 小麦、卵、乳は使用していますか？"
            persistent-placeholder
            outlined
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" class="pt-0 pb-0">
          <v-textarea
            v-model="topping.help.answer"
            label="ヘルプ (回答)"
            placeholder="例) はい。小麦、卵と牛乳を使用しています。"
            persistent-placeholder
            rows="5"
            outlined
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="pt-0 pb-0">
          <SortableToggleList
            v-bind:subtitle="`選択肢`"
            v-bind:datas="choices"
            v-bind:menus="menus"
            :handle-toggle-mode="toggleMode"
            :handle-update-choices="updateChoices"
            :handle-sort-choices="sortChoices"
          />
        </v-col>
      </v-row>

      <!-- ボタン -->
      <v-row class="mt-16">
        <v-col cols="12" class="pt-0 pb-0">
          <v-btn
            v-show="showDeleteBtn"
            block
            text
            @click="onClickDelete"
            :disabled="editMode"
          >
            <span class="text-decoration-underline">オプションを削除する</span>
          </v-btn>
          <v-btn
            :disabled="!valid || !validChoices || editMode"
            class="primary mt-4"
            large
            block
            @click="onClickRegister"
            >{{ btnName }}</v-btn
          >
        </v-col>
      </v-row>
    </v-form>

    <!-- dialog -->
    <v-dialog v-model="delInfo.show" max-width="300" persistent>
      <v-card>
        <v-card-title> 削除してよろしいですか? </v-card-title>
        <v-card-text class="pb-6 pt-12 text-center">
          <v-btn class="mr-3" text @click="delInfo.show = false">
            キャンセル
          </v-btn>
          <v-btn color="primary" text @click="executeDelete(delInfo.id)">
            削除する
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { PROVIDE_STATUS } from "../../constants";
import { createUuid } from "../../util/commonUtil";

export default {
  name: "Option",
  components: {
    SortableToggleList: () => import("./components/list/SortableToggleList"),
  },
  props: {},
  data: () => ({
    // form
    valid: true,
    rules: {
      required: [(v) => !!v || "必須入力です"],
      length15: (v) => (v || "").length <= 15 || "15文字以内でお願いします",
    },

    toppingId: null,

    // 選択肢 (部品)
    choices: [], // 選択肢の値 (部品専用)
    editMode: false, // 選択肢モード(true:編集 false:表示)

    btnName: "",
    showDeleteBtn: false,
    delInfo: { show: false, id: null },

    // 確認ダイアログ
    todoInfo: {
      show: false,
      maxWidth: 300,
      contents: "TODO",
    },
    // snack
    snack: false,
    snackColor: "",
    snackText: "",
  }),
  computed: {
    ...mapGetters(["progress", "provideStatusList", "topping", "menus"]),
    validChoices: function () {
      return this.choices.length != 0;
    },
  },
  watch: {
    topping: {
      handler: function (newValue) {
        this.choices = this.createTmpChoices(newValue);
      },
    },
  },
  async mounted() {
    const toppingId = this.$route.params.optionId;
    this.toppingId = toppingId;
    console.log(`toppingId(optionId): ${toppingId}`);

    await this.fetchValuesForTopping({ toppingId: this.toppingId });

    if (toppingId) {
      this.initEditMode();
    } else {
      this.initNewMode();
    }
  },
  methods: {
    ...mapActions([
      "fetchValuesForTopping",
      "fetchTopping",
      "createTopping",
      "updateTopping",
      "deleteTopping",
    ]),
    initNewMode() {
      this.btnName = "オプションを登録する";
      this.showDeleteBtn = false;
    },
    async initEditMode() {
      this.btnName = "オプションを更新する";
      this.showDeleteBtn = true;
    },
    createTmpChoices(topping) {
      console.log(`createTmpChoices`, topping);
      if (!topping || topping.values == null) {
        return [];
      }
      const values = topping.values;
      const tmpChoices = values.map((value) => {
        let newData = value;
        newData.taxInclude = "税込"; //TODO
        newData.taxKbn = "8%軽減"; //TODO
        return newData;
      });

      console.log(`tmpChoices: ${JSON.stringify(tmpChoices)}`);
      return tmpChoices;
    },
    add() {
      //TODO 画面遷移
      this.todoInfo = {
        show: true,
        maxWidth: 300,
        contents: "[画面遷移] オプション(登録)",
      };
    },
    edit(topping) {
      //TODO 画面遷移
      console.log(`edit: ${JSON.stringify(topping)}`);
      this.todoInfo = {
        show: true,
        maxWidth: 300,
        contents: "[画面遷移] オプション(編集)",
      };
    },
    del(delInfo) {
      console.log(`del: ${JSON.stringify(delInfo)}`);
    },
    onSortEnd(updatedItems) {
      //TODO DB 更新 (group.sort)
      console.log(`onSortEnd: ${JSON.stringify(updatedItems)}`);
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "表示順を更新しました";
    },
    async onClickRegister() {
      try {
        // 編集モード
        const editMode = this.toppingId ? true : false;

        const dbData = this.createToppingForDb(editMode);
        console.log(`[register]dbData=${JSON.stringify(dbData)}`);

        // 登録処理
        if (editMode) {
          await this.updateTopping({ topping: dbData });
        } else {
          await this.createTopping({ topping: dbData });
        }

        // 画面遷移(オプション一覧)
        this.$router.push(`/option`);
      } catch (e) {
        console.error(e);
      }
    },
    createToppingForDb(editMode) {
      // toppingId 発行
      const toppingId = editMode ? this.toppingId : createUuid();

      const topping = this.topping;
      const memo = topping.memo === undefined ? null : topping.memo;
      const newValues = this.createChoicesForDb(this.choices);
      console.log("@oldValues", this.choices);
      console.log("@newValues", newValues);

      const dbData = {
        toppingId: toppingId,
        name: topping.name,
        memo: memo,
        help: topping.help,
        values: newValues, // 選択肢
      };
      return dbData;
    },
    createChoicesForDb(choices) {
      if (!choices || choices.length == 0) {
        return [];
      }
      return choices.map((choice) => {
        let value = {};
        const refMenuId = choice.refMenuId;
        if (refMenuId) {
          // 参照メニュー
          value.id = choice.id;
          value.ref = true;
          value.refMenuId = refMenuId;
        } else {
          // 手入力
          value.id = String(choice.id); // migration (id->String)
          value.name = choice.name;
          value.amount = choice.amount;
          value.provideStatus = choice.provideStatus ?? PROVIDE_STATUS[0].id; // 提供可能 (default)
        }
        return value;
      });
    },
    onClickDelete() {
      // 確認ダイアログ表示
      this.delInfo = { show: true, id: this.toppingId };
    },
    async executeDelete(toppingId) {
      if (!toppingId) {
        throw Error("not implemented");
      }

      this.delInfo.show = false; // とじる(即)
      await this.deleteTopping({ toppingId: toppingId });

      // 画面遷移(オプション一覧)
      this.$router.push(`/option`);
    },
    //--------------------
    // 選択肢 (部品)
    //--------------------
    toggleMode(editMode) {
      console.log(`[option]toggleMode -> edit: ${editMode}`);
      this.editMode = editMode;
    },
    updateChoices(newChoices) {
      console.log(`[option]updateChoices -> ${JSON.stringify(newChoices)}`);
      this.choices = newChoices;
    },
    sortChoices(newChoices) {
      console.log(`[option]sortChoices -> ${JSON.stringify(newChoices)}`);
      this.choices = newChoices;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn.v-size--large {
  font-size: 1.4rem;
}
</style>
